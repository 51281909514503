export const CLEAN_AUTH = "CLEAN_AUTH";
export const LOGIN = "LOGIN";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const SET_LANGUAGE = "SET_LANGUAGE";

export const SET_AUTH_USER = "SET_AUTH_USER";
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";

export const SET_EXT = "SET_EXT";
export const CLEAN_NAV = "CLEAN_NAV";

export const ROLE_ADMIN = "admin";
export const ROLE_STAFF = "staff";

export const PLATFORM_REDIRECT = {
  defaultRedirect: `${process.env.REACT_APP_REDIRECT_APP}`,
  almondFactory: `${process.env.REACT_APP_REDIRECT_APP_ALMOND_FACTORY}`,
  rrhhEval: `${process.env.REACT_APP_REDIRECT_APP_RRHH_EVAL}`,
  rrhh: `${process.env.REACT_APP_REDIRECT_APP_RRHH}`,
  sampleTags: `${process.env.REACT_APP_REDIRECT_APP_SAMPLE_TAGS}`,
  provider: `${process.env.REACT_APP_REDIRECT_APP_PROVIDER}`,
  vivero: `${process.env.REACT_APP_REDIRECT_APP_VIVERO}`,
  controllingSap: `${process.env.REACT_APP_REDIRECT_APP_CONTROLLING}`,
};

export const LANGUAGE_ES = {
  code: "es",
  label: "language.languages.es",
};
export const LANGUAGE_EN = {
  code: "en",
  label: "language.languages.en",
};
export const LANGUAGE_PT = {
  code: "pt",
  label: "language.languages.pt",
};

export const LANGUAGES = [LANGUAGE_ES, LANGUAGE_PT, LANGUAGE_EN];
